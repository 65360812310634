header {
  width: 100vw;
  height: 80px;
  position: fixed;
  background-color: #ffffff;
  transition: background-color 0.5s ease;
  z-index: 99;
  top: 0;
  box-shadow: 0px 1px 4px #eee;
  animation: grow 0.5s ease forwards;
}

.Bolo_Header_Status {
  width: 85%;
  height: 100%;
  margin: auto;
  position: relative;
  display: flex;
  max-width: 1440px;
  background-color: transparent;
}
@keyframes grow {
  0% {
    top: -100px;
    transition: transform 0.5s ease;
  }
  100% {
    top: 0;
    transition: transform 0.5s ease;
  }
}

.Bolo_Header {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;

  align-items: center;
}

.Bolo_Header img {
  width: 90%;
}

.Image.active {
  animation: appear 0.5s ease forwards;
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.Image.deactive {
  animation: disappear 0.25s ease forwards;
}

@keyframes disappear {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.Logo_Section {
  width: auto;
  height: auto;
  margin: auto 0;
}

.Language_Section {
  width: auto;
  height: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 10px;
}

.Logo_Section a {
  width: auto;
  height: auto;
}

.Language_Section img {
  width: 20%;
  cursor: pointer;
}

.Language_Section p {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  text-align: left;
  cursor: pointer;
}

@media screen and (min-width: 280px) and (max-width: 727px) {
  header {
    height: 64px;
  }

  .Bolo_Header_Status {
    width: 90%;
  }

  .Bolo_Header {
    width: 100%;
  }
  .Bolo_Header img {
    width: 80%;
  }

  .Language_Section img {
    width: 25%;
  }

  .Language_Section p {
    font-size: 16px;
  }
}


@media screen and (max-width:525px){
  .hideInSmallDevive{
    display: none;
  }
}
