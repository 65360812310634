.Homepage_Section {
  width: 100%;
  height: 100vh;
  display: flex;
  margin: auto;
  background-color: #f0f3ff;
  overflow: hidden;
  /* overflow-y: scroll; */
  /* scroll-snap-type: y mandatory; */
  /* scroll-behavior: smooth; */
}

img {
  user-select: none;
}

* {
  outline-color: #182f83;
}

.Homepage_Section::-webkit-scrollbar {
  width: 0.1px;
}

.Snap_Section {
  width: 100%;
  height: 100vh;
  background: linear-gradient(138.54deg, #182f83 6.98%, #050a1d 95.6%);
  animation: slide 1s ease forwards;
  animation-delay: 7.2s;
  /* scroll-snap-align: start; */
}

.Info_Section {
  width: 0%;
  height: 100vh;
  background-color: #f0f3ff;
  visibility: hidden;
  opacity: 0;
  animation: slidein 1s ease forwards;
  animation-delay: 7.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

@keyframes slidein {
  0% {
    width: 0%;
    opacity: 0;
    visibility: hidden;
  }
  100% {
    width: 50%;
    opacity: 1;
    visibility: visible;
  }
}

@keyframes slide {
  from {
    width: 100%;
  }
  to {
    width: 50%;
  }
}
.Privacy_Section {
  width: 85%;
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
}

.Privacy_Section a {
  text-decoration: none;
  color: #565656;
}

.Privacy_Section h5 {
  width: max-content;
  font-family: "Inter", sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0.02em;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #565656;
}

.Mobile_Text {
  display: none;
}

.Apps {
  display: flex;
  gap: 50px;
}

.Apps img {
  cursor: pointer;
}

.App_Info {
  width: max-content;
  height: auto;
  margin: auto;
  padding: 24px 40px 40px 40px;
  border: 1px solid #182f83;
  border-radius: 20px;
}

.App_Info p {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 29.05px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-top: 0;
}

/* .App_Links h6 {
  width: max-content;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  position: relative;
  display: inline-block;
} */

/* .App_Links h6::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #ff9700 0%, #ffffff 49.4%, #138808 100%);
} */

.Info_Contents {
  width: 90%;
  margin: auto;
}

.Info_Contents p {
  width: max-content;
  font-family: "Inter", sans-serif;
  font-size: 34px;
  font-weight: 700;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #182f83;
  margin: 0;
  margin-top: 10px;
}

.Info_Contents h3 {
  width: max-content;
  color: #121212;
  font-family: "Inter", sans-serif;
  font-size: 56px;
  font-weight: 800;
  line-height: 67.77px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.Image_Section {
  width: 100%;
  height: 100vh;
  margin: auto;
}

.Image_Sub_Section {
  width: 100%;
  height: 100vh;
  margin: auto;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.Image_Sub_Section_Map {
  width: 50%;
  position: absolute;
}

.Bolo_Icon_Container {
  position: relative;
  width: 85%;
  height: 100%;
  margin: auto;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.Bolo_Icon_Container h5 {
  position: absolute;
  left: 0;
  bottom: 0px;
  color: #adb4ce;
  font-family: "Inter", sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0.02em;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  opacity: 0;
  animation: fading 1s ease forwards;
  animation-delay: 7.8s;
}

@keyframes fading {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Main_Logo {
  position: relative;
  width: 200px;
  height: auto;
  user-select: none;
}

.Main_Moto {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.Main_Moto h2 {
  color: #ffffff;
  font-size: 20px;
  margin: 0;
  font-weight: 350;
}

.Main_Text {
  opacity: 1;
  width: 100%;
  transform-origin: center;
  transform-style: preserve-3d;
  user-select: none;
}

.Main_Text.add {
  animation: flip1 0.8s ease forwards;
}

/* .Main_Text.visible {
  opacity: 1;
}

.Main_Text.hidden {
  opacity: 0;
  visibility: hidden;
} */

@keyframes flip1 {
  0% {
    opacity: 0;
    /* width: 0%; */
    transform: rotateX(180deg);
    scale: 1.1;
  }
  100% {
    opacity: 1;
    /* width: 80%; */
    transform: rotateX(360deg);
    scale: 1;
  }
}

.Content_Section {
  width: 85%;
  height: 100%;
  margin: auto;
  overflow-y: auto;
  max-width: 1440px;
}

.Content_Section::-webkit-scrollbar {
  width: 0.1px;
}

.Bolo_Box {
  width: 100%;
  height: 80%;
  margin: auto;
  display: flex;
  background-color: #eef2ff;
  border-radius: 40px;
  margin-top: 300px;
}

.Bolo_Text_Container {
  width: 80%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Bolo_Container_T {
  width: 80%;
  height: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Bolo_Text_Container h2 {
  color: #2e2e2e;
  font-family: "Inter", sans-serif;
  font-size: 60px;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  margin-bottom: 0;
}

.Bolo_Text_Container h3 {
  color: #454545;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: left;
  margin-top: 20px;
}

.Bolo_Text_Container p {
  color: #454545;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  text-align: left;
  margin-top: 50px;
}

.Bolo_Image_Container {
  width: 50%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Bolo_Image_Container p {
  color: #182f83;
  font-family: "Inter", sans-serif;
  font-size: 31.32px;
  font-weight: 700;
  line-height: 30.91px;
  text-align: left;
}

.Images_Container {
  width: 50%;
  height: auto;
  position: absolute;
  top: 45%;
  left: 50%;
  display: grid;
  gap: 25px;
  transform: translate(-50%, -50%);
  justify-content: center;
}

.Main_Logo_1 {
  width: 200px;
  height: auto;
  margin: auto;
  position: relative;
}

.Main_Logo_1.active {
  animation: appear1 1s ease forwards;
}

@keyframes appear1 {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.Sub_Text {
  width: 250px;
  position: relative;
  transform: scale(0);
}

.Sub_Text.active {
  animation: appear1 1s ease forwards;
  animation-delay: 1s;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.Popup_Box {
  width: auto;
  height: auto;
  max-width: 290px;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  top: -100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: slidedown 1s ease forwards;
  position: relative;
}

@keyframes slidedown {
  from {
    top: -100vh;
  }
  to {
    top: 0vh;
  }
}

.Popup_Box h1 {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 800;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 10px;
}

.Popup_Box p {
  width: 90%;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0;
}

.Subscribe_Button input {
  width: 320px;
  height: 40px;
  padding: 8px 24px;
  color: #2e2e2e;
  background-color: #f0f3ff;
  border: none;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24.2px;
  transition: outline 1s ease;
}

.Bolo_Subscribe {
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
}

.Bolo_Subscribe span {
  width: max-content;
  margin: 0;
  font-family: "Inter", sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 26.63px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.Bolo_Subscribe p {
  width: max-content;
  margin-bottom: 10px;
  font-family: "Inter", sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 26.63px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.Bolo_Subscribe input:focus {
  outline: none;
}

.Subscribe_Button {
  width: 100%;
  height: auto;
  margin: auto;
  display: flex;
  position: relative;
  align-items: center;
  border: 1px solid #787878;
  border-radius: 10px;
  background-color: #f0f3ff;
}

.Subscribe_Button button {
  background-color: transparent;
  color: #aaaaaa;
  border: none;
  font-size: 18px;
  cursor: pointer;
  font-weight: 700;
  padding: 8px 24px;
  padding-left: 0;
  cursor: default;
}

.Subscribe_Button input::-webkit-input-placeholder {
  color: #2e2e2e;
  opacity: 0.3;
  font-weight: 400;
  font-size: 16px;
}

.Bolo_Subscribe h6 {
  font-size: 14px;
  font-weight: 400;
  color: #ff0000;
  margin: 0;
  margin-top: 5px;
  position: absolute;
  text-align: center;
  bottom: -20px;
}

.Bolo_Subscribe img {
  width: 30px;
}

.Margin_Box {
  width: 85%;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 120px;
  max-width: 1440px;
}

.Margin_Box p {
  text-align: justify;
}

.Margin_Box h1,
.Margin_Box h2,
.Margin_Box h3 {
  color: #2e2e2e;
}

.Margin_Box p,
.Margin_Box ul li {
  color: #454545;
  font-size: 16px;
}

.Margin_Box ul li {
  line-height: 22px;
}

.Radio_Input {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Radio_Input input {
  accent-color: #182f83;
  cursor: pointer;
}

.Submit_Delete {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.Submit_Delete button {
  background-color: #182f83;
  border: 1px solid #182f83;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  font-weight: 700;
  padding: 8px 24px;
}

.delete_error {
  color: #ff0000;
  position: absolute;
}

.App_Links {
  width: auto;
  height: auto;
  margin: auto;
}

@media screen and (min-width: 1001px) and (max-width: 1500px) {
  .Main_Logo {
    width: 180px;
  }
  .Main_Text {
    width: 90%;
  }
  .Info_Contents p {
    font-size: 24px;
    margin-top: 8px;
  }

  .Info_Contents h3 {
    font-size: 36px;
  }

  .App_Info p {
    font-size: 20px;
  }

  .Apps img {
    width: 150px;
  }

  /* .App_Links h6 {
    font-size: 14px;
  } */

  .Bolo_Subscribe p,
  .Bolo_Subscribe span {
    font-size: 20px;
  }

  .Bolo_Subscribe input {
    width: 250px;
  }
}

@media screen and (min-width: 728px) and (max-width: 1000px) {
  .Main_Logo {
    /* top: 90px; */
    width: 100px;
  }

  .Sub_Text {
    /* top: 320px; */
    width: 200px;
  }

  .Main_Text {
    width: 90%;
  }
  .Info_Contents p {
    font-size: 20px;
    margin-top: 8px;
  }

  .Info_Contents h3 {
    font-size: 28px;
  }

  .App_Info p {
    font-size: 18px;
  }

  .App_Info {
    padding: 10px 20px 20px 20px;
  }

  .Main_Moto h2 {
    font-size: 14px;
  }

  .Apps img {
    width: 100px;
  }

  /* .App_Links h6 {
    font-size: 10px;
  } */
  .Bolo_Subscribe p,
  .Bolo_Subscribe span {
    font-size: 20px;
  }

  .Bolo_Subscribe input {
    width: 250px;
  }
}

@media screen and (min-width: 280px) and (max-width: 727px) {
  /* .Main_Logo {
     top: 100px; 
    width: 135px;
  }

  .Image_Sub_Section {
    background-size: contain;
  }

  .Main_Logo_1 {
    width: 135px;
  }

  .Sub_Text {
    width: 180px;
  } */

  .Margin_Box {
    width: 90%;
  }

  .Margin_Box p,
  .Margin_Box ul li {
    color: #454545;
    font-size: 14px;
  }

  .footer-text,
  .Privacy_Section {
    display: none;
  }

  .Mobile_Text {
    width: 90%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 10px;
    margin: auto;
    margin-top: 20px;
  }

  .App_Links a {
    text-decoration: none;
    color: #565656;
  }

  .App_Links h5 {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.02em;
    text-align: right;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #565656;
    margin: 0;
  }

  .Homepage_Section {
    flex-direction: column;
  }

  .Main_Logo {
    width: 120px;
  }

  .Image_Section,
  .Image_Sub_Section {
    height: 100vh;
    animation: slideup 1s ease forwards;
    animation-delay: 7.2s;
  }

  .Snap_Section {
    width: 100%;
    height: 100vh;
    animation: slideup 1s ease forwards;
    animation-delay: 7.2s;
  }

  .Info_Section {
    width: 100%;
    height: 0;
    animation: slideout 1s ease forwards;
  }

  @keyframes slideout {
    0% {
      height: 0;
      opacity: 0;
      visibility: hidden;
    }
    100% {
      height: 55vh;
      opacity: 1;
      visibility: visible;
    }
  }

  @keyframes slideup {
    from {
      height: 100vh;
    }
    to {
      height: 45vh;
    }
  }

  .Info_Contents {
    height: 40%;
  }

  .App_Links {
    height: 48%;
  }
  .Info_Contents p {
    font-size: 18px;
    margin-top: 10px;
  }

  .Info_Contents h3 {
    font-size: 26px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .Apps {
    gap: 10px;
  }

  .App_Links {
    width: 100%;
  }

  .App_Info p {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .App_Info {
    padding: 10px 20px 20px 20px;
  }

  .Main_Moto h2 {
    font-size: 14px;
  }

  .Apps img {
    width: 130px;
  }

  /* .App_Links h6 {
    font-size: 12px;
    margin-top: 10px;
  } */
  .Popup_Box {
    width: 70%;
  }

  .Popup_Box h1 {
    font-size: 20px;
  }

  .Popup_Box p {
    font-size: 14px;
    line-height: normal;
  }

  .Bolo_Subscribe {
    width: 90%;
    margin: auto;
  }

  .Bolo_Subscribe p,
  .Bolo_Subscribe span {
    font-size: 18px;
    margin: 0;
  }

  .Bolo_Subscribe input {
    width: 61vw;
  }

  .Subscribe_Button {
    justify-content: space-between;
  }

  .Subscribe_Button button {
    padding: 8px 12x;
  }
}
